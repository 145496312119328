

















































































import { Component, Vue, Prop, Emit, Inject } from 'vue-property-decorator';
import { CustomField, CustomFieldValue, CustomFieldType, createCustomField, CustomerType } from '../../common/types/AllTypesExports';
import CustomFieldTextEditor from './CustomFieldTextEditor.vue';
import CustomFieldCheckboxEditor from './CustomFieldCheckboxEditor.vue';
import CustomFieldNumberEditor from './CustomFieldNumberEditor.vue';
import CustomFieldSetOfOptionsEditor from './CustomFieldSetOfOptionsEditor.vue';
import CustomFieldDateTimeEditor from './CustomFieldDateTimeEditor.vue';
import CustomFieldWebsiteURLEditor from './CustomFieldWebsiteURLEditor.vue';
import CustomFieldFileUploadEditor from './CustomFieldFileUploadEditor.vue';

@Component({
  components: {
      CustomFieldTextEditor,
      CustomFieldCheckboxEditor,
      CustomFieldNumberEditor,
      CustomFieldSetOfOptionsEditor,
      CustomFieldDateTimeEditor,
      CustomFieldWebsiteURLEditor,
      CustomFieldFileUploadEditor
  },
})
export default class CustomFieldEditor extends Vue {
    //External
    @Prop({required: true, default: () => createCustomField()})
    fieldConfig!: CustomField;

    @Prop({ required: false, default: "" })
    idPrefix?: string;

    @Prop({default: function() { return {value: "", isSummation: false} }})
    value!: CustomFieldValue;

    @Prop()
    columnClass?: string;

    @Prop({required: false, default: ''})
    validatorScope!: string;

    @Prop({ required: false, default: false })
    disabled!: boolean;

    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;

    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Prop({ required: false, default: CustomerType.Person })
    customerType!: CustomerType;

    @Inject('validator')
    $validator: any;

    //Internal
    customFieldTypes: object = CustomFieldType;
    hasValueOnLoad: boolean = false;

    //Functions
    created() {
        this.value.customFieldId = this.fieldConfig.id;

        if (this.value && this.value.value && this.value.value.length > 0) {
            this.hasValueOnLoad = true;
        }

        this.emitInput();
    }
    @Emit('input')
    emitInput() {
        return this.value;
    }

    get readOnly() {
        // Although fieldConfig is currently not used on frontend we are extending the condition so that we prevent any potential problems in the future
        return this.fieldConfig.readOnlyOnFrontend || (this.fieldConfig.readOnlyAfterInitialValue && this.hasValueOnLoad) || this.disabled;
    }

    get computedId() {
        return this.idPrefix + 'CustomField_' + this.fieldConfig.id;
    }
}

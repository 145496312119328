import { Component, Vue, Prop, Watch, Inject, Emit } from 'vue-property-decorator';
import { CreditCardTokenData, createCreditCardTokenData, PaymentProcessorOptions, createPaymentProcessorOptions, SavedCreditCard, ApiErrorResponse, BasicSelectListItem, createBasicSelectListItem, AutoPayOption } from '@/common/types/AllTypesExports';
import { PropOptions } from 'vue';
import StripeCardInput from './StripeCardInput.vue';
import QuickBooksPaymentsCardInput from './QuickBooksPaymentsCardInput.vue';
import CreditCardEntryForm from './CreditCardEntryForm.vue';
import WarningAlert from '../../components/errors/WarningAlert.vue';
import { default as CreditCardEntryFormClass } from './CreditCardEntryFormVue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { paymentsRepo } from '@/common/repositories/PaymentsRepo';
import { AxiosResponse } from 'axios';
import CustomerSelector from '../membership/CustomerSelector.vue';

@Component({
    name: 'CreditCardContainer',
    components: {
        StripeCardInput,
        QuickBooksPaymentsCardInput,
        FieldValidationErrors,
        CreditCardEntryForm,
        WarningAlert,
        CustomerSelector
    }
})
export default class CreditCardContainer extends Vue {
    @Prop({ required: true, default: () => createPaymentProcessorOptions() } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: true, default: () => "" })
    donateTransactionFeeLabel!: string;

    @Prop({ required: false, default: () => "" })
    donateTransactionFeeDescription!: string;

    @Prop({ required: false, default: () => 0 })
    donateTransactionFeePercentage!: number;

    @Prop({ required: false, default: () => "" })
    donateTransactionFeeChargeType!: string;

    @Prop({ required: false, default: () => true })
    creditCardActive!: boolean;

    @Prop({ required: false, default: () => "" })
    cardRestrictionMessage!: string;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Prop({ required: false, default: () => false })
    forceSaveCard!: boolean;

    @Prop({ required: false, default: () => false })
    isDonatingTransactionFee!: boolean;

    @Prop({ required: false, default: () => 0 })
    readonly proposedDonateTransactionFeeAmount!: number;

    @Prop({ required: false, default: () => [] })
    autoPayOptions!: AutoPayOption[];

    @Prop({ required: false, default: false })
    setMemberTypeDefaultBillableParty: boolean | undefined;

    savedCardId: string = "";
    newCardSelected: boolean = false;
    failedToPullSavedCards: boolean = false;
    billableParties: BasicSelectListItem[] = [];
    billableParty = createBasicSelectListItem();
    errorMessage: string = '';
    localWarningMessage: string = '';
    isCheckingForBillableParties: boolean = false;
    noBillablePartiesMessage: string = 'No eligible billable parties found for credit card. If you believe this to be an error, please contact us.';

    creditCardTokenData = createCreditCardTokenData(<CreditCardTokenData>{
        storedCardId: this.savedCardId,
        last4Digits: this.savedCardId.substr(-4),
        billablePartyId: this.processorOptions.billablePartyId,
        autoPayOptions: this.autoPayOptions
    });

    @Inject('validator')
    $validator: any;

    get preventCreditCardUsageMessage() {
        return this.cardRestrictionMessage || this.localWarningMessage || '';
    }

    get preventCreditCardUsage() {
        return !!this.preventCreditCardUsageMessage.length;
    }

    get showSavedCards() {
        if (this.processorOptions && this.processorOptions.savedCards) {
            return this.processorOptions.enableSaveCreditCard && this.processorOptions.savedCards.length > 0;
        }
        return false;
    }

    get showDonateTransactionFeeCheckbox() {
        return this.proposedDonateTransactionFeeAmount > 0 && (this.processorOptions.donateTransactionFeePercentage ?? 0) > 0;
    }

    get isDonatingTransactionFeeDefault() {
        return this.processorOptions.donateTransactionFeeChargeType?.toLowerCase() === 'OptOut'.toLowerCase();
    }

    get isForcedSaveCard() {
        return this.forceSaveCard || this.autoPayOptions.filter(x => x.enabled && !x.externalPaymentMethodId).length > 0;
    }

    get autoPayOptionsOrdered() {
        return this.autoPayOptions.sort((a, b) => Number(b.isCurrentUser) - Number(a.isCurrentUser))
    }

    get showAutoPay() {
        return this.processorOptions.enableAutoPay && this.autoPayOptions.length > 0;
    }

    truncateName(name: string) {
        return (name.length > 30) ? name.substr(0, 29) + '...' : name;
    }

    expirationText(month: number, year: number) {
        return 'Exp. ' + (month < 10 ? '0' + month : month) + ' / ' + year;
    }

    @Emit('card-token-updated')
    emitCreditCardTokenUpdate() {
        return this.creditCardTokenData;
    }

    @Watch('isDonatingTransactionFee', { immediate: false })
    onIsDonatingTransactionFeeChange(value: boolean) {
        this.$emit('update:is-donating-transaction-fee', value);
    }

    @Watch('billableParty')
    onBillablePartyChange(newBillableParty: BasicSelectListItem) {
        Object.assign(this.billableParty, newBillableParty);
        this.creditCardTokenData.billablePartyId = this.billableParty.id;
        this.emitCreditCardTokenUpdate();
    }

    // Watch for external changes to billablePartyId (change purchaser)
    @Watch('processorOptions.billablePartyId')
    onBillablePartyIdChange(newBillablePartyId: number) {
        if (isNaN(newBillablePartyId)) {
            this.updateBillToDropdownSelector(null);
        }
        else if (newBillablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('SalesReceipt', newBillablePartyId, this.setMemberTypeDefaultBillableParty)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';
                    this.localWarningMessage = '';

                    if (this.billableParties.length) {
                        const newBillableParty = this.billableParties.find(b => b.id === newBillablePartyId) ?? this.billableParties[0];
                        Object.assign(this.billableParty, newBillableParty);
                        this.onBillablePartyChange(this.billableParty);
                        this.updateBillToDropdownSelector(this.billableParty);
                    }
                    else {
                        this.localWarningMessage = this.noBillablePartiesMessage;
                    }

                    this.onIsCreditCardProcessingPrevented(this.preventCreditCardUsage);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }
    }

    onIsCreditCardProcessingPrevented(value: boolean) {
        this.$emit('on-is-credit-card-processing-prevented', value);
    }

    onCreditCardPaymentTokenReady(card: CreditCardTokenData) {
        this.$emit('on-payment-token-ready', card);
    }

    onErrorToggle(hasError: boolean, errorMsg: string) {
        this.$emit('on-error-toggle', hasError, errorMsg);
    }

    onBillingStateChange(billingState: string) {
        this.$emit('update:credit-card-billing-state', billingState);
    }

    expandNewCardEntry() {
        if (!this.locked) {
            this.savedCardId = "";
            this.newCardSelected = true;
        }
    }

    selectStoredCard(selectedCardId: string) {
        if (!this.locked) {
            this.newCardSelected = false;
            this.savedCardId = selectedCardId;

            if (this.savedCardId) {
                this.$emit('update:credit-card-billing-state', this.getSavedCardBillingState());
            }
        }
    }

    createPaymentToken() {
        
        if (this.savedCardId) {
            var savedCardToken = createCreditCardTokenData(<CreditCardTokenData>{
                storedCardId: this.savedCardId,
                autoPayOptions: this.autoPayOptions,
                last4Digits: this.savedCardId.substr(-4),
                billablePartyId: this.billableParty.id,
                billingState: this.getSavedCardBillingState()
            });
            this.onCreditCardPaymentTokenReady(savedCardToken);
        }
        else {
            let creditCardContainer = <CreditCardEntryFormClass>this.$refs.creditCardEntryForm;
            creditCardContainer.createPaymentToken(this.autoPayOptions, this.billableParty.id);
        }
    }

    mounted() {
        this.isDonatingTransactionFee = this.isDonatingTransactionFeeDefault;

        if (this.creditCardTokenData.billablePartyId) {
            this.isCheckingForBillableParties = true;
            paymentsRepo.getBillableParties('SalesReceipt', this.processorOptions.customerForBillableParties ?? this.creditCardTokenData.billablePartyId, this.setMemberTypeDefaultBillableParty)
                .then((billableCustomers: BasicSelectListItem[]) => {
                    this.billableParties.splice(0, this.billableParties.length, ...billableCustomers);
                    this.errorMessage = '';
                    this.localWarningMessage = '';

                    if (this.billableParties.length) {
                        const currentBillableParty = this.billableParties.find(b => b.isDefault) ?? this.billableParties[0];
                        Object.assign(this.billableParty, currentBillableParty);
                        this.onBillablePartyChange(this.billableParty);
                        this.updateBillToDropdownSelector(this.billableParty);
                    }
                    else {
                        this.localWarningMessage = this.noBillablePartiesMessage;
                    }

                    this.onIsCreditCardProcessingPrevented(this.preventCreditCardUsage);
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = axiosResponse.data.message;
                })
                .finally(() => {
                    this.isCheckingForBillableParties = false;
                });
        }

        if (this.processorOptions.unauthorized) {
            return;
        }

        if (this.processorOptions.enableSaveCreditCard && this.processorOptions.savedCards && this.processorOptions.savedCards.length === 0) {
            paymentsRepo.getSavedCreditCards()
                .then((savedCards: SavedCreditCard[]) => {
                    if (savedCards.length > 0) {
                        this.processorOptions.savedCards.splice(0, this.processorOptions.savedCards.length, ...savedCards);
                        this.savedCardId = this.processorOptions.savedCards[0].cardId;
                    }
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => { });
        }
        else if (this.processorOptions.enableSaveCreditCard && this.processorOptions.savedCards && this.processorOptions.savedCards.length > 0) {
            this.savedCardId = this.processorOptions.savedCards[0].cardId;
        }
    }

    // Update the 'Bill To' drop down value if changed from outside the vue app
    updateBillToDropdownSelector(value: any) {
        const customerSelector = this.$refs.billablePartySelector as any;
        if (customerSelector && typeof customerSelector.setSelectedCustomer === 'function') {
            customerSelector.setSelectedCustomer(value);
        }
    }

    onGuestDataUpdated(name: string, guestName: boolean) {
        const index = guestName ? this.billableParties.findIndex(item => item.id == -1) : this.billableParties.findIndex(item => item.id == -2);

        if (index === -1) {
            if (guestName) {
                this.billableParties.push({
                    id: -1,
                    displayName: name,
                    taxable: true,
                    isDefault: false,
                });
            }
            else {
                this.billableParties.push({
                    id: -2,
                    displayName: name,
                    taxable: true,
                    isDefault: false,
                });
            }
        } else {
            this.billableParties[index].displayName = name;
        }
        if (this.billableParties.length === 1) {
            const newBillableParty = this.billableParties[0];
            Object.assign(this.billableParty, newBillableParty);
            this.onBillablePartyChange(this.billableParty);
            this.updateBillToDropdownSelector(this.billableParty);
        }
    }

    getSavedCardBillingState() {
        if (this.savedCardId) {
            var card = this.processorOptions.savedCards.find(x => x.cardId === this.savedCardId);

            return card?.billingState;
        }

        return "";
    }
}
import { Component, Vue, Prop, Emit, Inject, Watch } from 'vue-property-decorator';
import * as NoviTypes from '../../common/types/AllTypesExports';

import FileUploader from '../../components/file-uploader/FileUploader.vue';


@Component({
    components: {
        FileUploader
    }
})
export default class CustomFieldFileUploadEditor extends Vue {
    //External
    @Prop({ required: true })
    fieldConfig!: NoviTypes.CustomField;

    @Prop({ required: true })
    value?: String;

    @Prop({ required: true })
    id!: string;

    @Prop({ required: false, default: '' })
    validatorScope!: string;

    @Prop({ required: false, default: false })
    readOnly!: boolean;

    @Prop({ required: false, default: false })
    hideDisabledMessage!: boolean;

    @Prop({ required: false, default: null })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    customFieldID!: number;
    file: any;

    //Functions
    created() {
        this.emitInput();
        this.customFieldID = this.fieldConfig.id;
    }

    @Emit('input')
    emitInput() {
        return this.file;
    }

    get required() {
        return (this.isRequired == null && this.fieldConfig.requiredFrontend) || (this.isRequired != null && this.isRequired);
    }
}
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PaymentContainer from '@/components/payment-container/PaymentContainer.vue';
import {default as PaymentContainerClass} from '@/components/payment-container/PaymentContainerVue';
import { DtoCustomer } from '@/common/types/membership/DtoCustomer';
import { DtoDuesLineItem } from '@/common/types/membership/DtoLineItem';
import { DtoProduct } from '@/common/types/ecommerce/DtoProduct';
import { customerRepo } from '@/common/repositories/CustomerRepo';
import '@/common/filters/AllFiltersExport';
import { AxiosResponse } from 'axios';
import { ApiErrorResponse, DtoMemberType, createPaymentProcessorOptions, PaymentProcessorOptions, PaymentContainerToken, PaymentTypeEnum, CustomerType } from '@/common/types/AllTypesExports';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import EventBus from '@/common/services/EventBus';
import { ApiPaymentErrorResponse, createApiPaymentErrorResponse } from '@/common/types/Payment/ApiPaymentErrorResponse';
import { DtoProductQuantity, createProductQuantity } from '@/common/types/ecommerce/DtoProductQuantity';
import { ProductType } from '@/common/types/ecommerce/ProductType';
import regexPattern from '@/components/directives/regexPattern-directive';
import dayjs from 'dayjs';
import { AutoRenewType } from '@/common/types/membership/AutoRenewType';
import { DtoCustomerCreateResponse } from '../../common/types/membership/DtoCustomerCreateResponse';
import DonationInput from '../../components/inputs/donation/DonationInput.vue';
import QuantityInput from '../../components/inputs/quantity/QuantityInput.vue';
import { PaymentProcessingStatus } from '../../common/types/Payment/PaymentProcessingStatus';
import { IEventRegistrationGlobals } from '../event-registration/EventRegistrationGlobals';
import { CouponValidationResult, createCouponValidationResult } from '@/common/types/membership/CouponValidationResult';

declare let noviNs: IEventRegistrationGlobals;

@Component({
    components: {
        PaymentContainer,
        FieldValidationErrors,
        DonationInput,
        QuantityInput
    },
    directives: {
        regexPattern
    }
})
export default class MembershipCheckout extends Vue { 
    @Prop({required: true})
    customer! : DtoCustomer;
    @Prop({required: true})
    memberType!: DtoMemberType;
    @Prop({ required: false })
    addSeats!: boolean;
    @Prop({ required: false })
    isRenewal!: boolean;

    isDonatingTransactionFee: boolean = noviNs.paymentProcessorOptions.donateTransactionFeeChargeType?.toLowerCase() === 'OptOut'.toLowerCase();
    paymentType: string = PaymentTypeEnum.creditCard.toString();

    //Internal
    productTypes: object = ProductType;
    duesLineItems: DtoDuesLineItem[] = [];
    removedDuesLineItems: DtoDuesLineItem[] = [];
    addons: { product : DtoProduct, quantity : DtoProductQuantity}[] = [];
    membershipExpires?: Date | string;
    isLoading: boolean = false;
    hasError: boolean = false;
    errorMessage: string = "";

    // Promo codes
    couponResult: CouponValidationResult = createCouponValidationResult();
    validatedDiscountCode: string = "";
    discountCodeInput: string = "";
    applyingDiscount: boolean = false;
    discountError: string = "";

    // Seat based member types
    seatQuantity: number | undefined | null = null;

    paymentProcessorOptions: PaymentProcessorOptions = createPaymentProcessorOptions({
        initialTotal: 0,
        enableSaveCreditCard: true,
        enableAutoPay: this.memberType.autoRenewType != AutoRenewType.Disabled && this.memberType.autoRenewType != AutoRenewType.AdminInitiated,
        enableACH: noviNs.paymentProcessorOptions.enableACH,
        enableSaveAch: noviNs.paymentProcessorOptions.enableSaveAch,
        savedAchs: noviNs.paymentProcessorOptions.savedAchs,
        donateTransactionFeeLabel: noviNs.paymentProcessorOptions.donateTransactionFeeLabel,
        donateTransactionFeeDescription: noviNs.paymentProcessorOptions.donateTransactionFeeDescription,
        donateTransactionFeePercentage: noviNs.paymentProcessorOptions.donateTransactionFeePercentage,
        donateTransactionFeeChargeType: noviNs.paymentProcessorOptions.donateTransactionFeeChargeType,
        autoPayRequired: this.autoPayRequired,
        unauthorized: noviNs.paymentProcessorOptions.unauthorized,
        taxRate: noviNs.paymentProcessorOptions.taxRate,
        enableCreditCard: noviNs.paymentProcessorOptions.enableCreditCard
    });

    mounted() {
        var firstInput = this.$el.querySelector("input");
        if (firstInput) {
            (<HTMLElement>firstInput).focus();
        }

        EventBus.$on("file-uploaded", this.clearFileUploadErrors);
    }

    get disableSubmit() {
        return this.isLoading;
    }

    get duesTotal() {
        return this.duesLineItems.reduce<number>((acc, line) => acc + line.line.price, 0);
    }

    get addonTotal() {
        return this.addons.reduce<number>((acc, addon) => {
            if (this.isSoldOut(addon) || this.isOutsidePurchaseWindow(addon)) {
                addon.quantity.quantity = 0;
            }
            if (addon.product.productType == ProductType.Donation) {
                return acc + (addon.quantity.price ? addon.quantity.price : 0);
            }
            else {
                return acc + (addon.quantity.quantity * (addon.product.memberPrice ? addon.product.memberPrice : 0))
            }
        }, 0);
    }
    
    get subTotal() {
        return this.duesTotal + this.addonTotal;
    }    

    get proposedDonateTransactionFeeAmount() {
        let ttl = this.subTotal - this.discount;
        if (ttl <= 0) {
            return 0;
        }

        return ttl * (this.paymentProcessorOptions.donateTransactionFeePercentage || 0);
    }

    get donateTransactionFeeAmount() {
        if (this.paymentType !== 'credit-card' || !this.isDonatingTransactionFee) {
            return 0;
        }

        return this.proposedDonateTransactionFeeAmount;
    }

    get taxTotal() {
        if (this.paymentProcessorOptions.taxRate == undefined) {
            return 0;
        }

        var addonDiscountTotal = this.getDiscountTotals()[2];

        let total = 0;
        this.addons.map((addon) => {
            if (addon.product.taxable) {
                total += addon.quantity.price * addon.quantity.quantity;
            }
        })

        if (addonDiscountTotal > total) {
            return 0;
        }

        return (total - addonDiscountTotal) * this.paymentProcessorOptions.taxRate;
    }

    get total() {
        return this.subTotal - this.discount + this.donateTransactionFeeAmount + this.taxTotal;
    }

    get autoPayRequired() {
        return this.memberType && this.memberType.autoRenewType == AutoRenewType.AutoPayRequired;
    }

    @Watch('total', { immediate: true })
    onTotalChanged(newTotal: number) {
        let paymentWidget = <PaymentContainerClass>this.$refs.payment;
        if (paymentWidget) {
            paymentWidget.onTotalUpdated(newTotal);
        }
    }

    @Watch('proposedDonateTransactionFeeAmount', { immediate: true })
    onProposedDonateTransactionFeeAmountUpdated(value: number) {
        const paymentContainer = this.$refs.payment as PaymentContainerClass;
        if (paymentContainer && paymentContainer.onProposedDonateTransactionFeeAmountUpdated) {
            paymentContainer.onProposedDonateTransactionFeeAmountUpdated(value);
        }
    }

    get subscriptionsPurchased() {
        return this.addons && this.addons.some((addOn) => addOn.product.isSubscription && addOn.quantity.quantity > 0);
    }

    @Watch('subscriptionsPurchased')
    onSubscriptionsPurchased(subscriptionsPresent: boolean) {
        let paymentContainer = <PaymentContainerClass>this.$refs.payment;
        paymentContainer.onForceSaveCardUpdated(subscriptionsPresent);
        paymentContainer.onForceSaveAchUpdated(subscriptionsPresent);
    }

    get formattedMembershipExpires() : string {
        if (!this.membershipExpires)
            return "";
        return new Date(this.membershipExpires).toLocaleDateString();
    }

    created() {
        if (this.addSeats) {
            this.seatQuantity = 1;
        } else if (this.memberType.seatBased && !this.isRenewal) {
            if (this.memberType.seatMinimum) {
                this.seatQuantity = this.memberType.seatMinimum;
            } else {
                this.seatQuantity = 1;
            }
        } else {
            this.loadLineItemsWithRetry();
            EventBus.$on("member-saved", this.loadLineItemsWithRetry);
        }

        if (this.memberType.requireImmediatePayment) {
            this.paymentProcessorOptions.enableInvoice = true; // Always show the tab, but display proper warning message
            this.paymentProcessorOptions.invoiceWarningMessage = "Sorry, we don't offer invoicing for this membership type. Please purchase separately or pay with Credit/Debit or ACH.";
        }
        this.paymentProcessorOptions.customerForBillableParties = this.customer.id;
        this.paymentProcessorOptions.billablePartyId = this.customer.defaultBillablePartyId ?? this.customer.id;
        this.paymentProcessorOptions.submitButtonText = "Complete Checkout";
    }

    loadLineItemsWithRetry(maxRetries = 2, currentRetry = 0) {
        this.isLoading = true;

        const loadDuesLines = () => {
            return customerRepo.getDuesLinesForCustomer(this.customer.id, this.memberType.id, this.addSeats, this.seatQuantity)
                .then(result => {
                    this.duesLineItems = result.lineItems;
                    this.addons = result.addons.map((product) => {
                        var pq = createProductQuantity();
                        pq.id = product.id;
                        pq.quantity = !product.hasLimitedQuantity && !product.hasPurchaseWindow
                            ? product.chargeByDefault || product.productType == ProductType.Donation
                                ? 1
                                : 0
                            : product.maxAvailable > 0 && this.isProductWithinPurchaseWindow(product)
                                ? product.chargeByDefault || product.productType == ProductType.Donation
                                    ? 1
                                    : 0
                                : 0;
                        pq.price = product.memberPrice ? product.memberPrice : 0;
                        return { product: product, quantity: pq };
                    });
                    this.membershipExpires = result.membershipExpires;
                })
                .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                    this.errorMessage = `Unable to load the dues checkout information. ${axiosResponse.data.message}`;
                    throw axiosResponse;
                });
        };

        const retryLoad = (): Promise<void> => {
            return loadDuesLines().catch(error => {
                if (currentRetry < maxRetries) {
                    return retryLoad();
                } else {
                    throw error;
                }
            });
        };

        return retryLoad().finally(() => {
            if (!this.errorMessage){
                    this.isLoading = false;
                }
        });
    }


    removeLine(lineIndex: number) {
        if (confirm("Are you sure that you want to remove this optional line item?")) {
            var removedItems = this.duesLineItems.splice(lineIndex, 1);
            if (removedItems.length > 0)
                this.removedDuesLineItems.push(removedItems[0]);
        }
    }

    clearCoupon() {
        this.couponResult = createCouponValidationResult();
        this.applyingDiscount = false;
        this.validatedDiscountCode = "";
        this.discountError = "";
    }   

    get discountDuesRules() {
        return this.duesLineItems
            .filter(duesLine => this.couponResult.discountDuesRules.includes(duesLine.duesRuleId));
    }

    get discountableProducts() {
        return this.addons
            .filter(addon => addon.product.productType != ProductType.Donation && this.couponResult.discountProducts.includes(addon.product.id));
    }

    get discount() {
        var total = this.getDiscountTotals();

        return total[0];
    }

    getDiscountTotals(): [totalDiscount: number, duesTotalDiscount: number, addonTotalDiscount: number] {
        var subTotal = 0;
        var duesTotal = 0;
        var addonTotal = 0;

        if (this.couponResult.discountType == 0) {
            var discounts: [total: number, type: string][] = [];

            this.discountDuesRules.forEach(duesRule => {
                discounts.push([(duesRule.line.price ?? 0), "dues"]);
            });

            this.discountableProducts.forEach(addon => {
                for (var i = 0; i < addon.quantity.quantity; i++) {
                    discounts.push([(addon.product.memberPrice ?? 0), "addon"]);
                }
            });

            if (this.couponResult.availableUses != null && this.couponResult.availableUses < discounts.length) {
                discounts = discounts.filter(x => x[0] > 0).sort(function (a, b) { return b[0] - a[0]; }).slice(0, this.couponResult.availableUses);
            }

            subTotal = discounts.reduce((acc, price) => acc + price[0], 0);
            duesTotal = discounts.filter(x => x[1] == "dues").reduce((acc, price) => acc + price[0], 0);
            addonTotal = discounts.filter(x => x[1] == "addon").reduce((acc, price) => acc + price[0], 0);

            var totalDiscount = Math.round((subTotal * this.couponResult.discountPercent) * 100) / 100;
            var duesTotalDiscount = Math.round((duesTotal * this.couponResult.discountPercent) * 100) / 100;
            var addonTotalDiscount = Math.round((addonTotal * this.couponResult.discountPercent) * 100) / 100;

            return [totalDiscount, duesTotal, addonTotalDiscount];
        }

        if (this.couponResult.discountType == 1 && this.couponResult.discountMethod == 0) {
            this.discountDuesRules.forEach(duesRule => {
                duesTotal += (duesRule.line.price ?? 0);
            });

            this.discountableProducts.forEach(addon => {
                addonTotal += ((addon.product.memberPrice ?? 0) * addon.quantity.quantity);
            });

            subTotal = duesTotal + addonTotal;

            var totalDiscount = Math.min(subTotal, this.couponResult.discountAmount)
            var discountPercentageTotal = totalDiscount / subTotal;

            var duesTotalDiscount = duesTotal * discountPercentageTotal;
            var addonTotalDiscount = addonTotal * discountPercentageTotal;

            return [totalDiscount, duesTotalDiscount, addonTotalDiscount]
        }

        if (this.couponResult.discountType == 1 && this.couponResult.discountMethod == 1) {
            var discounts: [total: number, type: string][] = [];

            this.discountDuesRules.forEach(duesRule => {
                discounts.push([Math.min((duesRule.line.price ?? 0), this.couponResult.discountAmount), "dues"]);
            });

            this.discountableProducts.forEach(addon => {
                for (var i = 0; i < addon.quantity.quantity; i++) {
                    discounts.push([Math.min((addon.product.memberPrice ?? 0), this.couponResult.discountAmount), "addon"]);
                }
            });

            if (this.couponResult.availableUses != null && this.couponResult.availableUses < discounts.length) {
                discounts = discounts.filter(x => x[0] > 0).sort(function (a, b) { return b[0] - a[0]; }).slice(0, this.couponResult.availableUses);
            }

            subTotal = discounts.reduce((acc, price) => acc + price[0], 0);
            duesTotal = discounts.filter(x => x[1] == "dues").reduce((acc, price) => acc + price[0], 0);
            addonTotal = discounts.filter(x => x[1] == "addon").reduce((acc, price) => acc + price[0], 0);

            return [subTotal, duesTotal, addonTotal];
        }

        return [0, 0, 0];
    }

    applyCoupon() {
        this.clearCoupon();

        // Trim the input
        this.discountCodeInput = this.discountCodeInput.trim();

        if (!this.discountCodeInput)
            return;

        this.applyingDiscount = true;


        customerRepo.validateCoupon(this.customer.id, this.memberType.id, this.addons.map(x => x.product.id), this.discountCodeInput)
            .then(result => {
                if (result.error) {
                    this.discountError = result.error;
                }
                else {
                    this.couponResult = result;
                    this.validatedDiscountCode = this.discountCodeInput;
                    this.discountCodeInput = "";
                }
            })
            .catch((axiosResponse: AxiosResponse<ApiErrorResponse>) => {
                this.discountError = `Unable to verify promo code: ${axiosResponse.data.message}`;
                throw axiosResponse;
            })
            .finally(() => {
                this.applyingDiscount = false; 
            });
    }

    handleIsDonatingTransactionFeeUpdated(value: boolean) {
        this.isDonatingTransactionFee = value;
    }

    onPaymentTypeUpdated(paymentType: string) {
        this.paymentType = paymentType;
    }

    onPaymentTokenReady(paymentToken: PaymentContainerToken) {
        var removedDuesRuleIds = this.removedDuesLineItems.map(l => l.duesRuleId);
        var addons = this.addons.map(pq => pq.quantity);

        var isAddSeats = this.addSeats && !this.isRenewal;

        customerRepo.membershipCheckout(this.customer.id, paymentToken, removedDuesRuleIds, addons, isAddSeats, this.isDonatingTransactionFee, this.memberType.id, this.validatedDiscountCode, this.seatQuantity)
            .then((result: DtoCustomerCreateResponse) => {
                var memberCheckoutType = this.addSeats ? "add-seats" : this.customer.membershipExpires ? "renewal" : "new";
                if (result.lostUserSession) {
                    window.location.href = "/login?ReturnUrl=/member-compass/new-membership&ErrorMessage=Sorry, something went wrong with your checkout. Your account was created but you have not been charged yet. Please log in to complete your checkout.";
                }
                else if (result.isAutoLoggedIn) {
                    var memberTypeId = this.memberType.id;
                    window.location.href = `/member-compass?checkout-success=true&checkout-type=${memberCheckoutType}&member-type-id=${memberTypeId}`;
                } else {
                    window.location.href = `/confirm-email?userId=${result.userId}&checkout-type=${memberCheckoutType}`;
                }
            })
            .catch((axiosResponse: AxiosResponse<ApiPaymentErrorResponse>) => {
                let paymentWidget = <PaymentContainerClass>this.$refs.payment;
                if (axiosResponse.status >= 500) {
                    if (!axiosResponse.data) {
                        axiosResponse.data = createApiPaymentErrorResponse(axiosResponse.data);
                        axiosResponse.data.message = `It appears your browser had an issue. In order to continue, please restart your browser and check your email or <a href="${noviNs.contactUsUrl}" target="_blank"><strong>contact us</strong></a> to see if your checkout went through.`;
                    }
                    axiosResponse.data.paymentProcessingStatus = PaymentProcessingStatus.Error;
                }
                paymentWidget.handleApiPaymentError(axiosResponse.data);
            });
    }

    isOutsidePurchaseWindow(addon: { product: DtoProduct, quantity: DtoProductQuantity }) {
        if (!addon.product.hasPurchaseWindow) {
            return false;
        }
        var isInPurchaseWindow = this.isProductWithinPurchaseWindow(addon.product);
        return !isInPurchaseWindow;
    }

    isSoldOut(addon: { product: DtoProduct, quantity: DtoProductQuantity }) {
        if (!addon.product.hasLimitedQuantity) {
            return false;
        }
        return addon.product.maxAvailable < 1;
    }

    @Watch("seatQuantity", { immediate: false })
    updateLineItems() {
        this.loadLineItemsWithRetry();
    }

    private isProductWithinPurchaseWindow(product: DtoProduct) {
        if (!product.hasPurchaseWindow) {
            return true;
        }
        const now = dayjs();

        var isInPurchaseWindow = (now.isSame(product.purchaseWindowStart) || now.isAfter(product.purchaseWindowStart)) && (now.isSame(product.purchaseWindowEnd) || now.isBefore(product.purchaseWindowEnd));
        return isInPurchaseWindow;
    }

    private isEmpty(array: number[]) {
        return !array || array.length === 0;
    }

    get memberTypeSeatMinimum() {
        if (!this.addSeats && this.memberType.seatMinimum) {
            return this.memberType.seatMinimum;
        }

        return 1;
    }

    get memberTypeSeatMaximum() {
        if (!this.addSeats && this.memberType.seatMaximum) {
            return this.memberType.seatMaximum;
        }

        return 999;
    }

    get showPromoCode() {
        return this.memberType == null || this.memberType.hasPromoCodes;
    }

    clearFileUploadErrors() {
        this.errors.clear(CustomerType.Person);
    }
}
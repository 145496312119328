import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsPaymentGlobals } from '../../common/types/Payment/NoviNsPaymentGlobals';
import PhoneInput from '../../components/inputs/phone/PhoneInput.vue';
import { AchTokenData, createAchTokenData, getQboErrorMessage, PaymentProcessorEnum, PaymentProcessorOptions, QboApiErrorResponse, AutoPayOption } from '@/common/types/AllTypesExports';
import { default as QuickBooksPaymentsCardInputClass } from './QuickBooksPaymentsCardInputVue';
import { qbPaymentsRepo } from '../../common/repositories/QuickbooksPaymentsRepo';
import { AxiosResponse } from 'axios';
import { PropOptions } from 'vue';
import routingNumberImage from '../../assets/routingNumber.svg';
import accountNumberImage from '../../assets/accountNumber.svg';

declare let noviNs: INoviNsPaymentGlobals;

type PhoneMapping = { [key: number]: string };

@Component({
    name: 'AchEntryForm',
    components: {
        PhoneInput,
        FieldValidationErrors,
    }
})
export default class AchEntryFormClass extends Vue {

    @Prop({ required: true } as PropOptions<PaymentProcessorOptions>)
    processorOptions!: PaymentProcessorOptions;

    @Prop({ required: false, default: () => true })
    validate!: boolean;

    @Prop({ required: false, default: () => false })
    locked!: boolean;

    @Prop({ required: false, default: () => false })
    saveAchOption!: boolean;

    @Prop({ required: false, default: () => false })
    forceSaveAch!: boolean;

    achData: AchTokenData = createAchTokenData();

    rawPhone: string = '';
    maskedPhone: string = '';

    private stripe?: stripe.Stripe;

    @Inject('validator')
    $validator: any;

    @Watch('forceSaveAch', { immediate: true })
    onForceSaveAch(forceSave: boolean) {
        if (this.saveAchOption && forceSave)
            this.achData.saveAch = true;
    }

    maskPhone() {
        let numbers = this.maskedPhone.replace(/\D/g, ''); // Extract numbers
        let masked = '';
        let phoneFormat: PhoneMapping = {
            0: '(',
            3: ') ',
            6: '-'
        };

        // Update masked display value with each character input.
        for (let i = 0; i < numbers.length; i++) {
            masked += (phoneFormat[i] || '') + numbers[i];
        }

        this.maskedPhone = masked;
        this.achData.phone = numbers;

        // Trigger validation after updating rawPhone
        this.$validator.validate('achPhone', this.rawPhone);
    }

    get accountTypesDisplay(): { value: string; label: string }[] {
        if (this.isPaymentProcessorQuickBookPayments) {
            return [
                { value: 'PERSONAL_CHECKING', label: 'Consumer Checking' },
                { value: 'PERSONAL_SAVINGS', label: 'Consumer Savings' },
                { value: 'BUSINESS_CHECKING', label: 'Business Checking' },
                { value: 'BUSINESS_SAVINGS', label: 'Business Savings' },
            ];
        }
        else if (this.isPaymentProcessorStripe) {
            return [
                { value: 'individual', label: 'Individual' },
                { value: 'company', label: 'Company' }
            ];
        } 

        return [];
    }

    get isPaymentProcessorQuickBookPayments() {
        return noviNs.paymentProcessor == PaymentProcessorEnum.quickBooksPayments;
    }

    get isPaymentProcessorStripe() {
        return noviNs.paymentProcessor == PaymentProcessorEnum.stripe;
    }

    onAchTokenReady(token: AchTokenData) {
        Object.assign(this.achData, token);
        this.$emit('on-payment-token-ready', token);
    }

    onError(errorMsg: string) {
        this.emitError(errorMsg);
    }

    emitError(errorMsg: string) {
        this.$emit('on-error-toggle', true, errorMsg);
    }

    createPaymentToken(autoPayOptions: AutoPayOption[], billablePartyId: number) {
        this.achData.autoPayOptions = autoPayOptions;
        this.achData.billablePartyId = billablePartyId;
        this.createAchPaymentToken(this.achData);
    }

    createAchPaymentToken(achEntry: AchTokenData) {
        if (this.isPaymentProcessorQuickBookPayments) {
            const achData = {
                bankAccount: {
                    name: achEntry.nameOnAccount,
                    accountNumber: achEntry.accountNumber,
                    routingNumber: achEntry.routingNumber,
                    phone: achEntry.phone.replace(/\D/g, ''),
                    accountType: achEntry.accountType
                }
            }

            return qbPaymentsRepo.getQuickbooksPaymentToken(achData)
                .then((token: string) => {
                    achEntry.achToken = token;
                    this.emitPaymentToken(achEntry);
                })
                .catch((axiosResponse: AxiosResponse<QboApiErrorResponse>) => {
                    // We are logging axiosResponse object to the console
                    console.log(axiosResponse);
                    this.emitError(`There was an issue processing your ACH payment. Details: ${getQboErrorMessage(axiosResponse.data)}`);
                });
        } else if (this.isPaymentProcessorStripe) {
            return this.stripe?.createToken('bank_account', {
                country: "US",
                currency: "usd",
                routing_number: achEntry.routingNumber,
                account_number: achEntry.accountNumber,
                account_holder_name: achEntry.nameOnAccount,
                account_holder_type: 'individual'
            })
                .then((result) => {
                    achEntry.achToken = result.token?.id || "";
                    this.emitPaymentToken(achEntry);
                });
        }
    }

    emitPaymentToken(achEntry: AchTokenData) {

        // Do not pass sensitive ACH data to novi
        const achTokenData: AchTokenData = {
            achToken: achEntry.achToken,
            storedBankAccountId: achEntry.storedBankAccountId,
            saveAch: achEntry.saveAch,
            autoPayOptions: achEntry.autoPayOptions,
            authorizationChecked: achEntry.authorizationChecked,
            last4Digits: achEntry.accountNumber.substr(-4),
            billablePartyId: achEntry.billablePartyId,
            routingNumber: '',
            accountNumber: '',
            confirmAccountNumber: '',
            accountType: '',
            nameOnAccount: '',
            phone: '',
            autoPayOptionsJson: ''
        };

        this.$emit('on-ach-token-ready', achTokenData);
    }

    stripeSetup() {
        this.stripe = Stripe(noviNs.stripeKey);
    }

    mounted() {
        if (this.isPaymentProcessorStripe) {
            this.stripeSetup();
        }

        // Initialize Routing Number Popover
        const routingNumberElement = this.$refs.popoverRoutingNumber as HTMLElement;
        ($(routingNumberElement) as any).popover({
            html: true,
            trigger: "hover",
            container: "body",
            content: `Your Routing number is the first set of numbers on the bottom of your checks.
            <img class="routing-number" src="${routingNumberImage}" alt="Routing Number Image"/>`
        });

        routingNumberElement.addEventListener("focus", () => {
            ($(routingNumberElement) as any).popover("show");
        });

        routingNumberElement.addEventListener("blur", () => {
            ($(routingNumberElement) as any).popover("hide");
        });

        // Initialize Account Number Popover
        const accountNumberElement = this.$refs.popoverAccountNumber as HTMLElement;
        ($(accountNumberElement) as any).popover({
            html: true,
            trigger: "hover",
            container: "body",
            content: `Your Account number is the second set of numbers on the bottom of your checks.
            <img class="account-number" src="${accountNumberImage}" alt="Account Number Image"/>`
        });

        accountNumberElement.addEventListener("focus", () => {
            ($(accountNumberElement) as any).popover("show");
        });

        accountNumberElement.addEventListener("blur", () => {
            ($(accountNumberElement) as any).popover("hide");
        });
    }
}
import { AxiosResponse } from 'axios';
import { BasicSelectListItem, CustomerInvoiceValidation, SavedCreditCard, AutoPayOption } from '../types/AllTypesExports';
import BaseNoviApiRepo from './BaseNoviApiRepo';

class PaymentsRepo extends BaseNoviApiRepo {
    getBillableParties(transactionType: string, customerId: number, getMemberTypeDefaultBillableParty: boolean = false) {
        return super.get<BasicSelectListItem[]>(`/novi-api/payments/billable-parties?transactionType=${transactionType}&forCustomerId=${customerId}&getMemberTypeDefaultBillableParty=${getMemberTypeDefaultBillableParty}`)
            .then(super.getNoviApiDataHandler<BasicSelectListItem[]>())
            .catch(super.getNoviApiErrorHandler<BasicSelectListItem[]>());
    } 

    getSalesTerms() {
        return super.get<BasicSelectListItem[]>('/novi-api/payments/sales-terms')
            .then(super.getNoviApiDataHandler<BasicSelectListItem[]>())
            .catch(super.getNoviApiErrorHandler<BasicSelectListItem[]>());
    }

    getDefaultSalesTerm(customerId?: number) {
        return super.get<{defaultSalesTermId: number}>(`/novi-api/payments/default-sales-term/${customerId}`)
            .then((axiosResponse: AxiosResponse<{ defaultSalesTermId: number }>) => {
                var data = super.getNoviApiDataHandler<{ defaultSalesTermId: number }>()(axiosResponse);
                return data.defaultSalesTermId;
            })
            .catch(super.getNoviApiErrorHandler<number>());
    }

    getSavedCreditCards() {
        return super.get<SavedCreditCard[]>('/novi-api/payments/credit-cards')
            .then(super.getNoviApiDataHandler<SavedCreditCard[]>())
            .catch(super.getNoviApiErrorHandler<SavedCreditCard[]>());
    }

    getSavedAch() {
        return super.get<SavedCreditCard[]>('/novi-api/payments/credit-cards')
            .then(super.getNoviApiDataHandler<SavedCreditCard[]>())
            .catch(super.getNoviApiErrorHandler<SavedCreditCard[]>());
    }

    validateCustomerCanBeInvoiced(customerId: number) {
        return super.get<CustomerInvoiceValidation>(`/novi-api/payments/validate-customer-can-be-invoiced/${customerId}`)
            .then(super.getNoviApiDataHandler<CustomerInvoiceValidation>())
            .catch(super.getNoviApiErrorHandler<CustomerInvoiceValidation>());
    }

    getTaxRate(shippingState?: string, billingState?: string, purchaserId?: number, eventId?: number) {
        return super.get<{ taxRate: number }>(`/novi-api/payments/tax-rate/?shippingState=${shippingState}&billingState=${billingState}&purchaserId=${purchaserId}&eventId=${eventId}`)
            .then((axiosResponse: AxiosResponse<{ taxRate: number }>) => {
                var data = super.getNoviApiDataHandler<{ taxRate: number }>()(axiosResponse);
                return data.taxRate;
            })
            .catch(super.getNoviApiErrorHandler<Number>());
    }

    getAutoPayOptions() {
        return super.get<{items: AutoPayOption[]}>('/novi-api/payments/auto-pay-options')
            .then(super.getNoviApiDataHandler<{ items: AutoPayOption[] }>())
            .catch(super.getNoviApiErrorHandler<{ items: AutoPayOption[] }>());
    }
}

export const paymentsRepo = new PaymentsRepo();
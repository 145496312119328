import Vue from 'vue';
import VeeValidate from 'vee-validate';
import { Attendee } from './types/event/DtoAttendee';

VeeValidate.Validator.extend('maxQuantity', {
    getMessage: (field: string, arg: string[]) => {
        if (arg.length > 1 && arg[1]) {
            return arg[1];
        }
        return `Max is ${arg[0]}`;
    },
    validate(value: number, arg: string[]) {
        return value <= parseInt(arg[0]);
    }
});

VeeValidate.Validator.extend('dangerContent', {
    getMessage: (field: string, arg: boolean) => {
        return arg ? `Value cannot contain '<' or '>'.` : ``;
    },
    validate(value: string, arg: boolean) {
        var forbiddenChars = ['<', '>']
        return !arg || forbiddenChars.filter(x => value.indexOf(x) > -1).length == 0;
    }
});

VeeValidate.Validator.extend('donationPrice', {
    getMessage: (field: string, arg: number[]) => {
        const minimumPrice = arg[0];
        return arg ? `Donation amount is lower than the minimum donation ($${minimumPrice.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}) for this product.` : ``;
    },
    validate(value: string, arg: number[]) {
        if (!value || !arg) {
            return true;
        }
        const memberPrice = parseFloat(value);
        const minimumPrice = arg[0];
        const isValid = memberPrice == 0 || memberPrice >= minimumPrice;
        return isValid;
    }
});

VeeValidate.Validator.extend('customMatch', {
    getMessage: (field: string) => {
        return `The ${field} does not match.`;
    },

    validate: (value: string, [otherValue]: string[]) => {
        return value === otherValue;
    }
});

VeeValidate.Validator.extend('numericLength', {
    getMessage: (field: string, params: { [key: string]: any }) => {
        const length = params[0];
        return `The ${field} field must have exactly ${length} numeric characters.`;
    },

    validate: (value: string, [length]: number[]) => {
        const numericValue = value.replace(/\D/g, '');  // Remove non-numeric characters
        return numericValue.length === parseInt(length.toString(), 10);
    }
});

VeeValidate.Validator.extend('uniqueEmail', {
    getMessage: (field: string) => {
        return `This email is already in use for this event. A unique address is required for each attendee.`;
    },

    validate: (value: string, { attendees }: { attendees?: Attendee[] } = {}) => {
        if (!attendees || !Array.isArray(attendees)) {
            return true; // If no attendees are provided, pass validation
        }

        const currentEmailLowerCase = value.toLowerCase();

        // Filter out null/undefined emails
        const otherEmails = attendees
            .map(attendee => attendee.email?.toLowerCase()) // Map to lowercase emails
            .filter(email => email); // Remove undefined or null emails

        const emailCount = otherEmails.filter(email => email === currentEmailLowerCase).length;

        // If the email appears more than once, validation fails
        return emailCount <= 1;
    }
});

VeeValidate.Validator.extend('noHtml', {
    getMessage: (field: string) => {
        return `The ${field} field cannot contain HTML.`;
    },

    validate: (value: string) => {
        return !/<[a-z][\s\S]*>/i.test(value);
    }
});

Vue.use(VeeValidate, {
    events: 'input|blur',
    useConstraintAttrs: false
});
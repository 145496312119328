import { Component, Vue, Prop, Watch, Inject, Emit } from 'vue-property-decorator';
import { DtoAddress, createDtoAddress, GoogleAddressComponentNameMap, createGoogleAddressComponentNameMap, GoogleAddressComponentValueMap, createGoogleAddressComponentValueMap } from '../../common/types/AllTypesExports';
import NoviSwitch from '../inputs/NoviSwitch/NoviSwitch.vue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import { INoviNsAddressGlobals } from '../../common/types/membership/NoviNsAddressGlobals';

declare let noviNs: INoviNsAddressGlobals;

@Component({
    components: {
        NoviSwitch,
        FieldValidationErrors
    }
})
export default class AddressInfo extends Vue {
    @Prop({ required: true, default: () => createDtoAddress(<DtoAddress>{address1: ''}) })
    address!: DtoAddress;

    @Prop({ required: false, default: () => false })
    enableAddressSettings!: boolean;

    @Prop({ required: false, default: () => createDtoAddress() })
    parentAddress!: DtoAddress;

    @Prop({ required: false, default: () => false })
    useParent!: boolean;

    @Prop({ required: false, default: () => false })
    required!: boolean;

    @Prop({ required: true, default: () => "" })
    label!: string;
    
    @Prop({required: false, default: () => Math.random().toString(36).substr(2, 9)})
    id!: string;

    @Prop({required: false, default: () => Math.random().toString(36).substr(2, 9)})
    validationScope!: string;

    @Prop({ required: false, default: () => false })
    disabled!: string;

    @Prop({required: false, default: false})
    showCopyBilling!: boolean;

    @Prop({ required: false, default: () => '' })
    instructions!: string;

    uniqueId: string = Math.random().toString(36).substr(2, 9);
    addressAutocomplete!: google.maps.places.Autocomplete;
    addressAutocompleteListener!: google.maps.MapsEventListener;
    useParentToggled = 'use-parent-toggled';
    addressClone!: DtoAddress;
    mapsIsInitialized = false;
    settingsOpened = false;
    settingsToggleOffEventSet = false;
    showCountry = !!this.address.country || noviNs.showCountryGlobally;
    
    // This object allows us to map the incoming google result keys
    addressComponent: GoogleAddressComponentNameMap = createGoogleAddressComponentNameMap();

    // This object allows us to map the incoming google result values
    addressComponentValues: GoogleAddressComponentValueMap = createGoogleAddressComponentValueMap();

    @Inject('validator') $validator: any;

    emitUseParentToggled() {
        this.$emit(this.useParentToggled, this.useParent);
    }

    get settingsAreOpen() {
        return this.settingsOpened;
    }

    get showUseParentSwitch() {
        return this.address.id > 0;
    }
    
    get showShowCountrySwitch() {
        return !noviNs.showCountryGlobally;
    }

    get addressElementId() {
        return this.validationScope;
    }

    get elementIds(){
        const idPrefix = this.id;
        const elements = {
            address1: `${idPrefix}__address1`,
            address2: `${idPrefix}__address2`,
            city: `${idPrefix}__city`,
            state: `${idPrefix}__state`,
            zip: `${idPrefix}__zip`,
            country: `${idPrefix}__country`,
            label: `${idPrefix}__label`
        }
        return elements;
    }

    get rules() {
        return this.required && !this.useParent ? "required|noHtml" : "noHtml";
    }

    get countryRules() {
        return noviNs.showCountryGlobally ? this.rules : "";
    }

    get computedAddressSettingsId() {
        return `${this.id}__address-settings--${this.validationScope}`;
    }

    get googleAutoCompleteInputClass(){
        return `${this.id}__address1--${this.validationScope}`;
    }

    get isReadonly() {
        return this.useParent || this.disabled;
    }

    copyFromBilling(){
        this.$emit('copy-from-billing-clicked');
        console.log("The copy billing link was clicked");
    }

    toggleSettings(show?: boolean){
        const self = this;
        if(typeof(show) === 'undefined'){
            this.settingsOpened = !this.settingsOpened;
        }
        else{
            this.settingsOpened = show;
        }

        // Setting an event handler for when we click outside of the address settings in order to toggle it closed
        if(this.settingsOpened && !this.settingsToggleOffEventSet){
            const timeout = setTimeout(() => {
                window.addEventListener('click', (e) => {
                    const containerElem = <Element>self.$refs.AddressSettingsContainer;
                    const toggleElem = <Element>self.$refs.AddressSettingsToggle;
                    if((containerElem && !containerElem.contains(<any>e.target)) && (toggleElem && !toggleElem.contains(<any>e.target))){
                        self.settingsOpened = false;
                    }
                });
                self.settingsToggleOffEventSet = true;
                clearTimeout(timeout);
            }, 30);
        }
    }

    toggleShowCountry(switched: boolean){
        this.showCountry = switched;
    }

    onLocalAddressUpdated() {
        this.$emit(`address-updated`, this.address);
    }

    toggleUseParent(switched: boolean) {
        this.useParent = switched;

        if (this.useParent) {
            this.removeGoogleAutoComplete();

            Object.assign(this.address, this.parentAddress);

            // If we are ending up with a new address ID from the parent we need to emit the event
            // with the original address ID so that child addresses (using this address as their parent value)
            // can update themselves accordingly
            if(this.address.id !== this.addressClone.id){
                const evtAddress = Object.assign({}, this.address);
                evtAddress.id = this.addressClone.id;
                this.$emit('address-updated', evtAddress);
            }
            else{
                this.$emit('address-updated', this.address);
            }
        }
        else{
            Object.assign(this.address, this.addressClone);
            this.setupGoogleAutocomplete();
            this.$emit('address-updated', this.address);
        }
        this.emitUseParentToggled();
    }

    removeGoogleAutoComplete() {
        if(this.addressAutocomplete){
            google.maps.event.removeListener(this.addressAutocompleteListener);
            google.maps.event.clearInstanceListeners(this.addressAutocomplete);
        }
    }

    setupGoogleAutocomplete() {
        if(!google){
            return;
        }
        const elemId = this.googleAutoCompleteInputClass;
        const addressElem = document.getElementsByClassName(elemId)[0];
        if(!addressElem){
            return;
        }
        this.addressAutocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>addressElem, { types: ["geocode"] });
        this.addressAutocompleteListener = this.addressAutocomplete.addListener("place_changed", () => {
            if(this.useParent){
                this.address.address1 = this.address.address1;
                return;
            }
            // Get the current selected place
            const place = this.addressAutocomplete.getPlace();

            //Extract the values out of the result and map it into the local addressComponentValues object
            if (!place || !place.address_components) {
                return;
            }
            place.address_components.forEach(addressComponent => {
                // This maps the component type (i.e. the 'city', 'state', 'zip', etc.)
                const addressPart = addressComponent.types[0];
                if (this.addressComponent[addressPart]) {
                    // Now we have a component type mapped over, we set the value (i.e. the street address, city, state, zip, etc.)
                    // We have to type cast the addressComponent because the GeocoderAddressComponent type does not have the appropriate signature 
                    this.addressComponentValues[addressPart] = (<{ [key: string]: any }>addressComponent)[this.addressComponent[addressPart]];
                }
            });

            // Set the values gathered from google maps to the local values
            this.address.address1 = `${this.addressComponentValues.street_number} ${this.addressComponentValues.route}`;
            this.address.address2 = `${this.addressComponentValues.subpremise}`;
            this.address.city = `${this.addressComponentValues.locality}` || `${this.addressComponentValues.postal_town}`;
            this.address.stateProvince = `${this.addressComponentValues.administrative_area_level_1}`;
            this.address.zipCode = `${this.addressComponentValues.postal_code}` || `${this.addressComponentValues.postal_code_prefix}`;
            this.address.country = `${this.addressComponentValues.country}`;
            this.$emit(`address-updated`, this.address);
        });
        this.mapsIsInitialized = true;
    }

    mounted() {
        if(!this.parentAddress){
            this.parentAddress = createDtoAddress();
        }
        this.addressClone = Object.assign({}, this.address);
        const self = this;
        this.setupGoogleAutocomplete();

        // This is here as a fallback mechanism in case the google maps sdk has not been loaded when mounted() is executed
        window.addEventListener('load', () => {
            if(!this.mapsIsInitialized){
                self.setupGoogleAutocomplete();
            }
        });
    }
}
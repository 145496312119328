import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { CustomerFieldConfig, DtoCustomer, createDtoCustomer, createCustomerFieldConfig, CustomerFormContext, CustomerType, DtoMemberField, VeeValidationRules, createDtoAddress, DtoAddress, createBasicSelectListItem, BasicSelectListItem } from '../../common/types/AllTypesExports';
import AddressInfo from './AddressInfo.vue';
import CharacterCount from '../inputs/CharacterCount.vue';
import Credentials from './Credentials.vue';
import Education from './Education.vue';
import Awards from './Awards.vue';
import VolunteerWork from './VolunteerWork.vue';
import CustomFieldSectionEditor from '../../components/custom-field/CustomFieldSectionEditor.vue';
import { Multiselect } from 'vue-multiselect';
import PhoneInput from '../../components/inputs/phone/PhoneInput.vue';
import ImageUploader from '../../components/image-uploader/ImageUploader.vue';
import CustomerSelector from '../../components/membership/CustomerSelector.vue';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';
import StringHelpers from '../../common/services/StringHelpers';
import { default as CustomerSelectorClass } from '@/components/membership/CustomerSelectorVue';
import { InputType } from 'zlib';

@Component({
    components: {
        AddressInfo,
        Awards,
        CharacterCount,
        Credentials,
        CustomerSelector,
        Education,
        CustomFieldSectionEditor,
        Multiselect,
        VolunteerWork,
        PhoneInput,
        ImageUploader,
        FieldValidationErrors
    }
})
export default class ProfileEdit extends Vue {
    @Prop({ required: true, default: () => createDtoCustomer() })
    customer!: DtoCustomer;

    @Prop({ required: true, default: () => createCustomerFieldConfig() })
    customerFieldConfig!: CustomerFieldConfig;

    @Prop({ required: false, default: () => CustomerFormContext.KeyInfo })
    formContext!: CustomerFormContext;

    @Prop({ required: true, default: () => CustomerType.Company })
    customerType!: CustomerType;

    @Prop({ required: false, default: () => 'Parent Member' })
    parentLabel!: string;

    @Prop({ required: false, default: () => '' })
    parentDescription!: string;

    @Prop({ required: false, default: () => '' })
    nameDescription!: string;

    @Prop({required: false, default: false})
    showNameFields!: boolean;

    @Prop({ required: false, default: 'Parent Member' })
    companyNameLabel!: string;

    @Prop({ required: false, default: true })
    allowEditNameFields!: boolean;
    
    @Prop({ required: false, default: false })
    showNoParentToggle!: boolean;

    @Prop({ required: false, default: false })
    hasBothCustomerTypes!: boolean;

    @Prop({ required: false, default: true })
    allowUpdateParent!: boolean;

    @Prop({ required: false, default: false })
    allowAddNewParent!: boolean;

    @Prop({ required: false, default: false })
    requirePersonDetails!: boolean;

    @Prop({ required: false, default: () => '' })
    billingAddressInstructions!: string;

    @Prop({ required: false, default: () => '' })
    shippingAddressInstructions!: string;

    @Prop({ required: false, default: () => '' })
    personalAddressInstructions!: string;

    @Prop({ required: false, default: () => '' })
    professionalEmailInstructions!: string;

    @Prop({ required: false, default: () => '' })
    personalEmailInstructions!: string;

    @Prop({ required: false, default: false })
    isMemberRegistration!: boolean;
          
    noParent = false;
    siblingCompanyName: string = '';

    @Inject('validator') $validator: any;

    addCounty(county: string) {
        this.customerFieldConfig.counties.unshift(county);
        this.customer.county = county;
    }

    get isForCompany() {
        return this.customerType === CustomerType.Company;
    }

    get showParentCustomerSelector() {
        return this.visibleFields['parentcustomername'] && this.allowUpdateParent;
    }

    get visibleCustomFields() {
        return this.customerFieldConfig.customFields.filter(c => c.keyInformation);
    }

    get customFieldSections() {
        return this.customerFieldConfig.customFieldSections.filter(section =>
            section.customFields.some(field => field.keyInformation === true));
    }

    get memberFieldValidationRules(): any {
        let result: any = {};


        this.customerFieldConfig.memberFields.forEach((memberField: DtoMemberField) => {
            var ruleObject = memberField ? <VeeValidationRules>{
                required: this.requiredFields[memberField.name.toLowerCase()],
                max: memberField.maxLength <= 0 ? 999999 : memberField.maxLength,
                min: StringHelpers.contains(memberField.name, 'phone') ? 0 : 0,
                email: memberField.email,
                url: memberField.url ? true : false,
                noHtml: true
            } : {};

            result[memberField.name.toLowerCase()] = ruleObject;
        });

        return result;
    }

    get visibleFields(): any {
        let result: any = {};

        this.customerFieldConfig.memberFields.forEach((memberField: DtoMemberField) => {
            var isFieldVisible = false;

            if (memberField) {
                isFieldVisible = this.formContext === CustomerFormContext.KeyInfo
                    && memberField.keyInformation
                    && !memberField.readOnlyOnFrontend
                    && (this.customer.id == 0 || !memberField.readOnlyOnFrontendAfterInitialValue);
            }

            result[memberField.name.toLowerCase()] = isFieldVisible;
        });

        return result;
    }

    get requiredFields(): any {
        let result: any = {};

        this.customerFieldConfig.memberFields.forEach((memberField: DtoMemberField) => {
            var isFieldRequired = (memberField && memberField.requiredOnFrontend) || (this.requirePersonDetails && memberField && (memberField.name === 'Email'));
            result[memberField.name.toLowerCase()] = isFieldRequired;
        });

        return result;
    }

    get fieldMaxLengths(): any {
        let result: any = {};

        this.customerFieldConfig.memberFields.forEach((memberField: DtoMemberField) => {
            var fieldMaxLength = memberField && memberField.maxLength || 0;
            result[memberField.name.toLowerCase()] = fieldMaxLength;
        });

        return result;
    }

    get memberFieldElementId() {
        const result: { [key: string]: string } = {};

        this.customerFieldConfig.memberFields.forEach(memberField => {
            if (!memberField || !memberField.name)
                return;
            // We're using just the name of the member field as an element Id since we're using Vee-Validate's scoped validation
            // to differentiate between more than one instance of the KeyInfo form. The scope is the customer type (i.e. person or company)
            const idVal = memberField.name.toLowerCase();
            result[memberField.name.toLowerCase()] = idVal;
        });
        return result;
    }

    emitShippingAddressUpdated(){
        this.$emit('shipping-address-updated', this.customer.shippingAddress);
    }

    emitBillingAddressUpdated(){
        this.$emit('billing-address-updated', this.customer.billingAddress);
    }

    emitPersonIsAllowed(isAllowed: boolean) {
        this.$emit('update:personIsAllowed', isAllowed);
    }

    onCopyFromBilling() {
        var self = this;
        const billing = this.customer.billingAddress && this.customer.billingAddress.address1 ? this.customer.billingAddress : createDtoAddress();
        const shippingId = (<DtoAddress>this.customer.shippingAddress).id;
        Object.assign(<DtoAddress>this.customer.shippingAddress, billing, { id: shippingId });
        self.customer.county = null;

        var billingAddress = (billing.address1 || "")
            + " " + (billing.address2 || "")
            + " " + (billing.city || "")
            + " " + (billing.stateProvince || "")
            + " " + (billing.zipCode || "")
            + " " + (billing.country || "");
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': billingAddress }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var countyResult = results[0].address_components.filter(x => x.types.includes("administrative_area_level_2"));
                if (countyResult.length) {
                    self.customer.county = countyResult[0].long_name.replace("County", "").trim();
                }
            }
        })
    }

    onParentUpdated(newParent: BasicSelectListItem) {
        this.customer.parentCustomerId = newParent.id;
        this.customer.parentCustomerName = newParent.displayName;
        this.$emit('update:parent', newParent);
    }

    onNoParentToggled() {
        this.$emit('update:noParent', this.noParent);
    }

    onEmailChange() {
        this.$emit('update:email');
    }

    onNameChange() {
        if (!this.isForCompany) {
            this.customer.name = `${this.customer.firstName} ${this.customer.lastName}`;
        } else {
            this.$emit('CompanyNameChange', this.customer.name);
        }
            
        this.$emit('update:name');
    }

    validate() {
        let parentCustomerSelector = <CustomerSelectorClass>this.$refs.parentCustomerSelector;
        if (parentCustomerSelector) {
            parentCustomerSelector.validateCustomerRequired();
        }
    }

    validateMultipleEmail() {
        let email = this.$refs.Email as HTMLInputElement;
        var msg = "";
        var emailRequired = this.requiredFields["email"];
        var hasValue = email.value.trim().length > 0;
        this.$validator.errors.remove('email', this.customerType);

        if (emailRequired && (typeof email.value === 'undefined' || email.value === null || email.value === '')) {
            msg = 'The Email field is required.';
        }
        else if (hasValue && !StringHelpers.isValidEmail(email.value.trim())) {
            msg = 'The Email field must be a valid email.';
        }

        if (msg) {
            this.$validator.errors.add({
                field: this.memberFieldElementId['email'],
                msg: msg,
                scope: this.customerType
            });
        }
    }

    created() { }

    mounted() {
        let links = document.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });
        /* If the parent fields are hidden during registration, treat the member as having selected "No Parent" */
        if (!this.visibleFields['parentcustomername'] && !this.customer.parentCustomerId && this.customerType == CustomerType.Person) {
            this.noParent = true;
            this.onNoParentToggled();
        }
        /* If the parent customer selector is visible, we need to set its initial value */
        else if (this.visibleFields['parentcustomername'] && this.customer.parentCustomerName) {
            let parentCustomerSelector = <CustomerSelectorClass>this.$refs.parentCustomerSelector;
            if (parentCustomerSelector) {
                parentCustomerSelector.setSelectedCustomer(createBasicSelectListItem({
                    id: this.customer.parentCustomerId,
                    displayName: this.customer.parentCustomerName
                }));
            }      
        }
        /* If no parent member was selected for the link company step, default checkbox to 'No Parent' on. Do NOT check if tenant has both customer types. */
        else if (!this.isMemberRegistration && this.customer.parentCustomerId == null && !(this.hasBothCustomerTypes)) {
            let noParentCheckbox = this.$refs.noParentCheckbox as HTMLElement;
            if (noParentCheckbox) {
                noParentCheckbox.click();
            }
        }

        var self = this;
        this.$root.$on('CompanyNameChange', function (name: string) {
            self.siblingCompanyName = name;
        });

        this.$emit("profileEditMounted");
    }
}
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { INoviNsImageUploaderGlobals } from './INoviNsImageUploaderGlobals';

declare let noviNs: INoviNsImageUploaderGlobals;

@Component({
    name: 'ImageUploader'
})
export default class ImageUploader extends Vue {
    @Prop({ required: false, default: () => "" })
    value!: string;

    @Prop({ required: false, default: () => "gif|jpe?g|png" })
    extensions!: string;

    @Prop({ required: false, default: () => 300 })
    imageHeight!: number;

    @Prop({ required: false, default: () => 300 })
    imageWidth!: number;

    @Inject('validator')
    $validator: any;

    get hasFile() {
        return !!this.value; 
    }

    get fullFilePath() {
        return this.hasFile ? noviNs.baseFileUrl + this.value : "";
    }

    get acceptedFileTypes() {
        return `/(\.|\/)(${this.extensions})$/i`;
    }
    
    created() {
        if (!noviNs.baseFileUrl) {
            alert("Could not locate the base file upload directory. Please contact an admin.");
        }
    }

    mounted() {
        noviNs.mountFileUploader(this.$refs.rootUploaderElement);

        // The code in file-uploader.js populates a hidden input with the new filename
        // Vue doesn't pick up on the change though, so we subscribe to an event that the JS publishes
        var self = this;
        var hiddenFileNameElem = this.$refs.hiddenFileName as HTMLObjectElement;
        hiddenFileNameElem.addEventListener('updateFileName', function (e: any) {
            self.value = e.detail;
            self.$emit('input', self.value);
        });

        // The code in file-uploader.js controls the visibility of the buttons, except for on page load
        if (!this.hasFile) {
            (this.$refs.imagePreview as HTMLObjectElement).classList.add("hidden");
            (this.$refs.removeButton as HTMLObjectElement).classList.add("hidden");
            (this.$refs.editCropButton as HTMLObjectElement).classList.add("hidden");
            (this.$refs.setCropButton as HTMLObjectElement).classList.add("hidden");
        }
    }
}
import { CustomFieldType } from './CustomFieldType.enum'
import { DateTimeType } from './DateTimeType.enum'

export interface CustomField {
    id: number,
    name: string,
    nameKey: string,
    displayName: string | null,
    instructions: string | null,
    requiredFrontend: boolean,
    requiredBackend: boolean,
    keyInformation: boolean,
    customFieldType: CustomFieldType,
    typeName: string,
    dateTimeType: DateTimeType | null,
    multiline: boolean,
    characterLimit: number,
    minDate: string | Date | null,
    maxDate: string | Date | null,
    wholeNumbers: boolean,
    minNumber: number,
    maxNumber: number,
    setOfOptions: string,
    allowMultipleSelections: boolean,
    limitSelections: boolean,
    limitSelectionsTo: number | null,
    readOnlyAfterInitialValue: boolean,
    readOnlyOnFrontend: boolean,
    allowMultipleFileUploads: boolean,
    limitFileUploadsTo: number
}


export function createCustomField(customField?: CustomField | any) {
    const obj = <CustomField>{
        id: 0,
        name: '',
        nameKey: '',
        displayName: null,
        instructions: null,
        requiredFrontend: false,
        requiredBackend: false,
        keyInformation: false,
        customFieldType: CustomFieldType.PlainText,
        typeName: '',
        dateTimeType: null,
        multiline: false,
        characterLimit: 0,
        minDate: null,// C# default DateTimeOffset '0001-01-01T00:00:00+00:00',
        maxDate: null, //C# default DateTimeOffset '0001-01-01T00:00:00+00:00',
        wholeNumbers: false,
        minNumber: 0,
        maxNumber: 0,
        setOfOptions: '',
        allowMultipleSelections: false,
        limitSelections: false,
        limitSelectionsTo: null,
        readOnlyAfterInitialValue: false,
        readOnlyOnFrontend: false,
        allowMultipleFileUploads: false,
        limitFileUploadsTo: 1
    };
    if (customField) {
        Object.assign(obj, customField);
    }
    return obj;
}
import { Component, Vue, Prop, Inject, Emit } from 'vue-property-decorator';
import { INoviNsFileUploaderGlobals } from './INoviNsFileUploaderGlobals';
import EventBus from '../../common/services/EventBus';
import FieldValidationErrors from '../../components/errors/FieldValidationErrors.vue';

declare let noviNs: INoviNsFileUploaderGlobals;

@Component({
        name: 'FileUploader',
        components: {
            FieldValidationErrors
        },
    })
export default class FileUploader extends Vue {
    @Prop({ required: false, default: () => "" })
    value!: string;

    @Prop({ required: false, default: () => "bmp|jpe?g|png|ico|pdf|doc?x|xls?x|zip|txt|ppt?x|dwg" })
    extensions!: string;

    @Prop({ required: true })
    customFieldID!: number;

    @Prop({ required: false, default: () => false })
    multiple!: boolean;

    @Prop({ required: false, default: () => 1 })
    fileLimit!: number;

    @Prop({ required: false, default: () => false })
    readOnlyOnFrontend!: boolean;

    @Prop({ required: false, default: () => false })
    readOnlyAfterInitialValue!: boolean;

    @Prop({ required: false, default: () => '' })
    validatorScope!: string;

    @Prop({ required: false, default: () => false })
    isRequired!: boolean;

    @Inject('validator')
    $validator: any;

    @Prop({ required: false, default: () => '' })
    fieldName!: string;

    mounted() {
        noviNs.mountFileUploader(this.$refs.rootUploaderElement);

        // The code in file-uploader.js populates a hidden input with the new filename
        // Vue doesn't pick up on the change though, so we subscribe to an event that the JS publishes
        var self = this;
        var hiddenFileNameElem = this.$refs.hiddenFileName as HTMLObjectElement;

        var inputName = (this.validatorScope == 'Company' ? "ParentCustomFieldFile_" : "CustomFieldFile_" ) + this.customFieldID;
        hiddenFileNameElem.addEventListener('updateFileName', function (e: any) {
            var inputValues = document.querySelectorAll('input[name="' + inputName + '"]');
            let groupedCustomFieldFiles: any[] = [];
            inputValues.forEach((inputValue: any) => {
                groupedCustomFieldFiles.push(JSON.parse(decodeURIComponent(inputValue.value)));
            });
            self.value = JSON.stringify(groupedCustomFieldFiles);
            self.$emit('input', self.value);
            EventBus.$emit("file-uploaded");
        });
    }

    @Emit('input')
    emitInput() {
        return this.value;
    }

    get hasFile() {
        return !!this.value && this.value != '[]';
    }

    get fullFilePath() {
        return this.hasFile ? noviNs.baseFileUrl + this.value : "";
    }

    get fileButtonLabel() {
        if (this.hasFile) {
            return this.multiple ? "Upload Additional Files" : "Replace File";
        } else {
            return this.multiple ? "Upload File(s)" : "Upload File";
        }
    }

    get showFileInput() {
        return !this.readOnlyOnFrontend && (!this.readOnlyAfterInitialValue || !this.hasFile);
    }

    get isParent() {
        return this.validatorScope == 'Company';
    }

    get required() {
        return this.isRequired ? 'required' : '';
    }
}
